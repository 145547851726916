import styled from "styled-components";

export const PlayVideo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 3rem;
  border: 1px solid ${(props) => (props.clear ? "#eee" : "#a7a7a7")};
  padding: 0.2rem 1rem;

  color: ${(props) => (props.clear ? "white" : "black")};
  font-size: 0.9rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: #222222;
  height: 100vh;
  width: 100%;
`;

export const VideoTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;

  width: 100%;
  height: 65px;

  color: white;
  background-color: black;
`;

export const VideoTitle = styled.span`
  display: flex;
  align-items: center;

  color: white;
  font-weight: 300;
`;

export const VideoActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;

  svg {
    font-size: 2rem;
    cursor: pointer;
    margin-left: 1rem;
  }
`;

export const VideoPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  border-radius: 0.5rem;

  width: 660px;
  height: 380px;
  background-color: black;

  box-shadow: 5px 5px 15px -4px rgba(0, 0, 0, 0.62);

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 400px;
    height: auto;
  }
`;

export const ReactPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 380px;
  background-color: inherit;
`;
